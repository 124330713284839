import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [MatIcon, TranslateModule, FlexLayoutModule],
    selector: 'iot-platform-ui-timeline-list',
    templateUrl: './timeline-list.component.html',
    styleUrls: ['./timeline-list.component.scss']
})
export class TimelineListComponent {
  title = input<string>();
  icon = input<string>();
}
