import { Component, input, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card/base-card.component';
import { SimpleCardComponent } from '../simple-card/simple-card.component';

@Component({
    selector: 'iot-platform-ui-display-value-card',
    imports: [SimpleCardComponent, TranslateModule, FlexLayoutModule, InfoDisplayPipe],
    templateUrl: './display-value-card.component.html',
    styleUrl: './display-value-card.component.scss'
})
export class DisplayValueCardComponent extends BaseCardComponent<unknown> {
  value = input<unknown>();
  unit = input<unknown>();
  description = input<unknown>();
  @Input() onValueClicked!: () => void; // Used for dynamic injection inputs
}
