import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    imports: [FlexLayoutModule, MatIcon, TranslateModule, MatButtonModule, MatProgressSpinner],
    selector: 'iot-platform-ui-download-card',
    templateUrl: './download-card.component.html',
    styleUrls: ['./download-card.component.scss']
})
export class DownloadCardComponent {
  loading = input<boolean>(false);
  cardText = input<string>('');
  buttonText = input<string>('');
  isVisible = input<boolean>(false);

  download = output<void>();
}
