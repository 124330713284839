import { Component, input, TemplateRef, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'iot-platform-ui-infos-display-card-property',
    imports: [FlexLayoutModule, TranslateModule, MatTooltip, MatButtonModule, MatIcon],
    templateUrl: './infos-display-card-property.component.html',
    styleUrl: './infos-display-card-property.component.scss'
})
export class InfosDisplayCardPropertyComponent {
  label = input<string>();
  value = input<unknown>();
  fxFlex = input<string>();
  cssClassName = input<string>();
  valueMetaInfo = input<
    Partial<{
      icon: string;
      svgIcon: string;
      tooltip: string;
      visible: boolean;
    }>
  >();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild(TemplateRef) public template!: TemplateRef<any>;
}
