import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiActions, AuthBusinessProfilesPageActions, AuthorizationService, AuthPageActions, AuthService, fromAuth } from '@iot-platform/auth';
import { CachedUsersService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile, UserAccount } from '@iot-platform/models/common';
import { SidenavMenuBlock } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationTreeService } from '../../services/navigation-tree.service';

@Component({
    template: '',
    standalone: false
})
export abstract class AbstractAppShellComponent implements OnInit, OnDestroy {
  navTree: SidenavMenuBlock[] = [];
  destroyed$: Subject<void> = new Subject<void>();
  enableBusinessProfileSwitching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  appTheme = 'default-theme';
  protected authService: AuthService = inject(AuthService);
  protected router: Router = inject(Router);
  protected navigationTreeService: NavigationTreeService = inject(NavigationTreeService);
  protected store: Store = inject(Store);
  businessProfiles$: Observable<BusinessProfile[]> = this.store.select(fromAuth.selectBusinessProfilesForAccount);
  businessProfile$: Observable<BusinessProfile> = this.store.select(fromAuth.selectSelectedBusinessProfileForAccount);
  currentUser$: Observable<UserAccount> = this.store.select(fromAuth.selectCurrentUser);
  currentUserLoading$: Observable<boolean> = this.store.select(fromAuth.selectAccountLoading);
  privileges$: Observable<any> = this.store.select(fromAuth.selectPrivileges);
  protected authorizationService: AuthorizationService = inject(AuthorizationService);
  protected storage: LocalStorageService = inject(LocalStorageService);
  sidenavState = {
    isOpen: this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) ? this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) === 'true' : true
  };
  protected cachedUsersService: CachedUsersService = inject(CachedUsersService);

  setNavigationTree?(): void;

  ngOnInit(): void {
    this.store
      .select(fromAuth.selectLoggedOut)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loggedOut) => {
        if (!loggedOut && this.authService.isLoggedInWithSSO()) {
          this.store.dispatch(AuthPageActions.retrieveSsoSession(this.authService.retrieveSsoTokens()));
        }
      });

    this.currentUser$
      .pipe(
        tap((user) => {
          if (user) {
            this.cachedUsersService.loadUsers();
          }
        }),
        filter((currentUser) => !currentUser),
        withLatestFrom(this.store.select(fromAuth.selectLoggedOut), this.store.select(fromAuth.selectSignOutPending)),
        takeUntil(this.destroyed$)
      )
      .subscribe(([_, loggedOut, logOutPending]) => {
        if (this.authService.isLoggedInWithSSO() || logOutPending) {
          return;
        }
        if (!loggedOut) {
          this.store.dispatch(AuthApiActions.retrieveSession());
        } else {
          this.storage.clear();
        }
      });
    this.setNavigationTree?.();
  }

  trackById(index: number, item: SidenavMenuBlock) {
    return item.id;
  }

  logout(): void {
    this.store.dispatch(AuthPageActions.signOut());
  }

  returnHome(): void {
    this.router.navigate(['/', 'home']);
  }

  toggleSidenav(): void {
    this.sidenavState.isOpen = !this.sidenavState.isOpen;
    this.storage.set(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY, this.sidenavState.isOpen.toString());
    this.dispatchResizeEvent();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onChangeBusinessProfile(selected: BusinessProfile, withRedirect = true): void {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile: selected,
        withRedirect
      })
    );
  }

  openMyProfile(): void {
    this.router.navigate(['my-profile']);
  }

  private dispatchResizeEvent(): void {
    // Workaround for responsive bug on mat-tab-link open
    // Reference : https://github.com/angular/components/issues/12234
    // Clean solution : https://github.com/angular/components/issues/6130
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      clearTimeout(timeout);
    }, 50);
  }
}
