import { NgClass } from '@angular/common';
import { Component, input, model, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ListViewItem } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'iot-platform-ui-list-view-items',
    imports: [FlexLayoutModule, MatIconModule, TranslateModule, NgClass, MatListModule],
    templateUrl: './list-view-items.component.html',
    styleUrl: './list-view-items.component.scss'
})
export class ListViewItemsComponent {
  items = input.required<ListViewItem[]>();
  selectedItem = model<ListViewItem>();
  selectionChange = output<ListViewItem>();

  onSelectItem(data: ListViewItem): void {
    this.selectedItem.set(data);
    this.selectionChange.emit(data);
  }
}
