import { CommonGenericModel } from '../common';
import { AssetStatusName } from './enums';

export enum AssetGroupStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface AssetGroupCreate {
  name: string;
  erpReference: string | null;
  description: string | null;
  siteId: string;
}

export interface AssetGroupTag {
  category: string;
  label: string;
}

export interface AssetGroupRelatedAsset {
  id: string;
  name: string;
  status: { name: AssetStatusName };
  priority: number;
  erpReference: { shipTo: string | null };
  product1: { name: string | null };
  product2: { name: string | null };
}

export interface AssetGroup extends CommonGenericModel {
  id: string;
  name: string;
  erpReference: string | null;
  description: string | null;
  status: AssetGroupStatus;
  site: { id: string; name: string };
  entity: { id: string; name: string };
  assets: AssetGroupRelatedAsset[];
  tags?: AssetGroupTag[]; // TODO : REMOVE OPTIONAL WHEN API READY
}
