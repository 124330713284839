import { NgClass } from '@angular/common';
import { Component, effect, inject, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';
import { DynamicDataService } from '@iot-platform/core';
import { GetUtils, TemplateDefUtils } from '@iot-platform/iot-platform-utils';
import { TemplateDef, TemplateDefDispatchEvent, TemplateDefDispatchEventType, TemplateDefType } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CardLoaderComponent } from '../../card-loader/card-loader.component';

/* eslint-disable no-underscore-dangle */
@Component({
    selector: 'iot-platform-ui-base-card-details-widget',
    templateUrl: './base-card-details-widget.component.html',
    imports: [MatIcon, NgClass, FlexLayoutModule, TranslateModule, CardLoaderComponent],
    styleUrls: ['./base-card-details-widget.component.scss']
})
export class BaseCardDetailsWidgetComponent {
  loading = input<boolean>(false);
  title = input<string>();
  icon = input<string>();
  svgIcon = input<string>();
  matIcon = input<string>();
  data = input<any>();
  cssClass = input<string>('');
  templateDefinition = input<TemplateDef[] | Array<TemplateDef[]>>([]);

  dispatchEvent = output<TemplateDefDispatchEvent>();

  TemplateDefType = TemplateDefType;
  TemplateDefDispatchEventType = TemplateDefDispatchEventType;

  protected dynamicDataService: DynamicDataService = inject(DynamicDataService);

  constructor() {
    effect(
      () => {
        const data = this.data();
        if (data) {
          this.initTemplateDefs(data);
        }
      }
    );
  }

  onDispatchEvent(type: TemplateDefDispatchEventType, def: TemplateDef, data: any): void {
    this.dispatchEvent.emit({
      type,
      def,
      data
    });
  }

  hasParams(def: TemplateDef): boolean {
    return (
      def.isLink &&
      TemplateDefUtils.hasParams({
        def,
        data: get(this.data(), 'element') || this.data()
      })
    );
  }

  getAsyncValue = (def: TemplateDef, element: unknown): Observable<unknown> | Array<Observable<unknown>> =>
    def.isList ? this._getAsyncValues(def, element) : this._getAsyncValue(def, element);

  applyPredicates = (def: TemplateDef, element: unknown): Observable<unknown> => {
    let value = GetUtils.get(element, `${def.attrKey}`, null);
    if (def.predicates && !!def.predicates.length) {
      value = def.predicates.reduce(
        (acc, predicate) =>
          predicate({
            element,
            def,
            value
          }),
        {}
      );
    }
    return of(value);
  };

  private initTemplateDefs(data: unknown): void {
    const templateDefinition = this.templateDefinition();
    if (templateDefinition) {
      templateDefinition.forEach((def: TemplateDef | TemplateDef[]) => {
        if (def instanceof Array) {
          def.forEach((_def: TemplateDef) => {
            _def.value$ = this.getValue(_def, data);
          });
        } else {
          def.value$ = this.getValue(def, data);
        }
      });
    }
  }

  private getValue = (def: TemplateDef, data: unknown): Observable<unknown> | Array<Observable<unknown>> => {
    const _data = def?.normalizeRawData ? def.normalizeRawData({ element: data, def }) : data;
    return def.api ? this.getAsyncValue(def, _data) : this.applyPredicates(def, _data);
  };

  private _getAsyncValue = (def: TemplateDef, element: unknown): Observable<unknown> =>
    this.dynamicDataService
      .getDynamicData(get(def, 'api.endpoint') as string, {
        ...def.api,
        rawData: element
      })
      .pipe(switchMap((result: unknown) => this.applyPredicates(def, result)));

  private _getAsyncValues = (def: TemplateDef, element: unknown): Array<Observable<unknown>> =>
    GetUtils.get(element, `${def.attrKey}`, []).map((item: any) => this._getAsyncValue(def, item));
}
