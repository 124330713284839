import { Component, input } from '@angular/core';
import { InfoDisplayPipe } from '@iot-platform/pipes';

@Component({
    imports: [InfoDisplayPipe],
    providers: [InfoDisplayPipe],
    selector: 'iot-platform-ui-detail-simple-card',
    templateUrl: './detail-simple-card.component.html',
    styleUrls: ['./detail-simple-card.component.scss']
})
export class DetailSimpleCardComponent {
  value = input<string>();
}
