import { DeviceType } from '../common';
import { DeviceStatusName } from './device.model';
import { AssetStatusName } from './enums';

export interface AssetOfAssociationByDevice {
  id: string;
  name: string;
  assetGroup: { name: string | null };
  status: { name: AssetStatusName };
  priority: number;
  erpReference: { shipTo: string | null };
  imageUrl: string;
  product1: { name: string } | null;
  product2: { name: string } | null;
}

export interface DeviceOfAssociationByDevice {
  id: string;
  name: string;
  status: { name: DeviceStatusName };
  imageUrl: string;
  type: DeviceType;
  assets: AssetOfAssociationByDevice[];
}

export interface AssociationByDevice {
  devices: DeviceOfAssociationByDevice[];
}
