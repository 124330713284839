import { DeviceType } from '../common';
import { DeviceStatusName } from './device.model';
import { AssetStatusName } from './enums';

export interface AssetGroupOfAssociationByAsset {
  id: string;
  name: string;
  assets: AssetOfAssociationByAsset[];
}

export interface AssetOfAssociationByAsset {
  id: string;
  name: string;
  status: { name: AssetStatusName };
  priority?: number;
  erpReference: { shipTo: string | null };
  imageUrl: string | null;
  product1: { name: string } | null;
  product2: { name: string } | null;
  devices: DeviceOfAssociationByAsset[];
}

export interface DeviceOfAssociationByAsset {
  id: string;
  name: string;
  status: { name: DeviceStatusName };
  imageUrl: string | null;
  type: DeviceType;
}

export interface AssociationByAsset {
  assetGroups: AssetGroupOfAssociationByAsset[];
  assets: AssetOfAssociationByAsset[];
}
