export interface DeviceCallLog {
  id: string;
  timestamp: number;
  callStatus: string;
  callDirection: string;
  command?: string;
  callDuration: number;
  data: number;
  events: number;
  connectorId: string;
}
