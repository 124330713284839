import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, ContentChildren, input, QueryList } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { InfosDisplayCardLabelComponent } from './infos-display-card-label/infos-display-card-label.component';
import { InfosDisplayCardPropertyComponent } from './infos-display-card-property/infos-display-card-property.component';

@Component({
    selector: 'iot-platform-ui-infos-display-card',
    imports: [FlexLayoutModule, TranslateModule, NgClass, NgTemplateOutlet],
    templateUrl: './infos-display-card.component.html',
    styleUrl: './infos-display-card.component.scss'
})
export class InfosDisplayCardComponent {
  cssClassName = input<string>();
  active = input<boolean>(false);
  separator = input<boolean>(false);
  type = input<'success' | 'info' | 'warning' | 'danger'>();

  @ContentChildren(InfosDisplayCardPropertyComponent) public properties!: QueryList<InfosDisplayCardPropertyComponent>;
  @ContentChild(InfosDisplayCardLabelComponent) public label!: InfosDisplayCardLabelComponent;
}
