import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListViewEvent, ListViewItem, OverviewCard } from '@iot-platform/models/common';
import { ListViewContentComponent } from '../list-view-content/list-view-content.component';
import { ListViewItemsComponent } from '../list-view-items/list-view-items.component';

@Component({
    selector: 'iot-platform-ui-list-view',
    imports: [FlexLayoutModule, ListViewContentComponent, ListViewItemsComponent],
    templateUrl: './list-view.component.html',
    styleUrl: './list-view.component.scss'
})
export class ListViewComponent {
  items = input.required<ListViewItem[]>();
  card = input.required<OverviewCard>();
  loading = input<boolean>(false);
  selectedItem = input<ListViewItem>(null);

  dispatchEvent = output<ListViewEvent>();

  onSelectionChange(item: ListViewItem): void {
    this.dispatchEvent.emit({
      type: 'selectionChange',
      data: item
    });
  }
}
